<template>
  <div class="d-flex flex-column justify-content-end">
    <div class="d-flex text-center py-0 align-items-center">
      <small class="py-0 mx-auto">
        {{ item.title }}
      </small>
    </div>
    <div class="text-center">
      <strong class="mx-auto" style="font-size: 22px;">
        {{ formatNumber(displayNumber) }}
      </strong>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useStatisticsCardState from './useStatisticsState'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      displayNumber: 0,
      interval: false,
      numberBoxData: [],
    }
  },
  setup(props) {
    const { itemCounts } = useStatisticsCardState()

    const count = computed(() => {
      const statsType = props.item.statsType
      const matchingItem = itemCounts.value.find(el => el[statsType] !== undefined)
      return matchingItem ? matchingItem[statsType] : 0
    })

    return {
      count,
    }
  },
  watch: {
    count: {
      immediate: true,
      deep: true,
      handler() {
        cancelAnimationFrame(this.interval)
        if (this.count === this.displayNumber) {
          return
        }

        const startTime = performance.now()
        const duration = 12000

        const animate = timestamp => {
          const progress = Math.min((timestamp - startTime) / duration, 1)
          const change = (this.count - this.displayNumber) * progress
          this.displayNumber += change

          if (progress < 1) {
            this.interval = requestAnimationFrame(animate)
          }
        }

        this.interval = requestAnimationFrame(animate)
      },
    },
  },
  async mounted() {
    this.displayNumber = this.count ? this.count : 0
  },
  methods: {
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return '0'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
  },
}
</script>

<style lang="scss">

</style>
