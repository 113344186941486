<template>
  <div class="card rounded-1 p-1">
    <div class="d-flex justify-content-between">
      <div class="card-group-title  flex-grow-1 text-nowrap">
        Item Counts
      </div>
      <dx-util-text-box v-if="hasPermission" v-model="accountNo" class="mr-half" placeholder="Account No" @key-down="onFilterByAccountNo" />
      <date-selection default-date="last30Days" @setdates="onDatesSelection" />
    </div>
    <div class="card-container py-1 px-0 mx-0">
      <div v-for="(item, index) in computedStatisticsList" :key="item.id" class="item" :class="{'full-row': index === 0}">
        <statistics-card :stats="item" class="" />
      </div>
    </div>
  </div>
</template>

<script>
import basicStatsService from '@/http/requests/stats/basicStatsService'
import StatisticsCard from './StatisticsCard.vue'
import useStatisticsCardState from './useStatisticsState'
import DateSelection from './DateSelection.vue'

export default {
  components: {
    'statistics-card': StatisticsCard,
    'date-selection': DateSelection,
  },
  data() {
    return {
      accountNo: '',
      beginDate: '',
      endDate: '',
      statisticsList: [
        {
          id: 1,
          title: 'Item Counts',
          description: '',
          icon: 'icon-psh-warehouse2',
          children: [
            {
              id: 1,
              title: 'Uploaded',
              statsType: 'uploaded_item_count',
            },
            {
              id: 2,
              title: 'In Transit',
              statsType: 'expected_item_count',
            },
            {
              id: 3,
              title: 'Inventory',
              statsType: 'item_quantity_in_inventory',
            },
            {
              id: 4,
              title: 'Shipped',
              statsType: 'items_shipped_count_total',
            },
          ],
          permission: null,
          active: true,
        },

        {
          id: 5,
          title: 'Inbound Boxes',
          description: 'Total box count received by warehouse.',
          icon: 'icon-psh-package-6',
          statsType: 'inbound_boxes_count',
          permission: 'resource_customer_account_no',
          active: false,
        },
        {
          id: 6,
          title: 'Closed Inbound Shipment Plans',
          description: 'Closed warehouse inbound shipment plans.',
          icon: 'icon-psh-load',
          statsType: 'closed_batches_count',
          permission: null,
          active: false,
        },
        {
          id: 7,
          title: 'FBA Shipments',
          description: 'Total FBA shipment count to Amazon.',
          children: [
            {
              id: 1,
              title: 'Batches',
              statsType: 'fba_batches_shipped_count',
            },
            {
              id: 2,
              title: 'Items',
              statsType: 'fba_items_shipped_count',
            },
          ],
          icon: 'icon-psh-shipment',

          permission: null,
          active: true,
        },
        {
          id: 8,
          title: 'WFS Shipments',
          description: 'Total WFS shipment count to Walmart.',
          children: [
            {
              id: 1,
              title: 'Batches',
              statsType: 'wfs_batches_shipped_count', // wfs
            },
            {
              id: 2,
              title: 'Items',
              statsType: 'wfs_items_shipped_count', // wfs
            },
          ],
          icon: 'icon-psh-delivery-truck',

          permission: null,
          active: true,
        },
        {
          id: 9,
          title: 'FBM Shipments',
          description: 'Total FBM shipment count.',
          children: [
            {
              id: 1,
              title: 'Boxes',
              statsType: 'fbm_label_box_count',
            },
            {
              id: 2,
              title: 'Items',
              statsType: 'fbm_shipment_count',
            },
          ],
          icon: 'icon-psh-door-delivery',
          permission: null,
          active: true,
        },
        {
          id: 10,
          title: 'Other Shipments',
          description: 'Total item count shipped for other purposes.',
          icon: 'icon-psh-logistic',
          children: [
            {
              id: 1,
              title: 'Others',
              statsType: 'other_shipment_count',
            },
          ],
          permission: null,
          active: true,
        },
      ],
    }
  },
  setup() {
    const {
      itemCounts,
    } = useStatisticsCardState()
    return {
      itemCounts,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    computedStatisticsList() {
      return this.statisticsList.filter(item => (item.permission === null || this.$can('read', item.permission)) && item.active === true)
    },
  },
  methods: {
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.fetchStatistics()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = null
        this.fetchStatistics()
      }
    },
    onDatesSelection(e) {
      this.beginDate = e.beginDate
      this.endDate = e.endDate
      this.fetchStatistics()
    },
    async fetchStatistics() {
      const statsTypeArray = this.computedStatisticsList.map(stat => {
        if (stat.children) {
          return stat.children.map(child => child.statsType)
        }
        return stat.statsType
      }).flat()

      const allFilters = statsTypeArray.map(type => ({
        statsType: type,
        accountNo: this.accountNo,
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: this.beginDate,
        endDate: this.endDate,
        timeInterval: null,
        q: '',
        topN: null,
      }))
      const result = await basicStatsService.getStatsByList(allFilters)
      this.itemCounts = result
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  gap: 12px;
  align-items: stretch;
  align-items: stretch;
}

@media (max-width: 1200px) {
  .card-container {
    grid-template-columns: 1fr !important;
  }
  .full-row {
   grid-column: span 1 !important;
  }
}
.item {
  overflow: hidden;
  justify-self: stretch;
    align-self: stretch;
}

.full-row {
 grid-column: span 2;
}
</style>
