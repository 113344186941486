<template>
  <div class="statistics-card h-100">
    <div class="w-100">
      <div class="d-flex flex-grow-1">
        <div class="flex-grow-1">
          <i :class="stats.icon" style="font-size: 42px;" class="" />
        </div>
        <div class="d-flex flex-wra*">
          <div v-for="item in stats.children" :key="item.id" class="mx-1">
            <number-box :item="item" />
          </div>
        </div>
      </div>
      <div>
        <strong style="font-size: 18px; font-weight: 900;" class="d-block">
          {{ stats.title }}
        </strong>
        <small class="d-block">
          {{ stats.description }}
        </small>
      </div>
    </div>

    <div v-if="false">
      <div class="d-flex flex-grow-1">
        <div class="flex-grow-1">

        </div>

      </div>

    </div>
    <div v-if="false" class="d-inline d-flex mx-0 px-0 h-100">
      <div class="statistics-card flex-grow-1">
        <div class="d-block">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberBox from './NumberBox.vue'

export default {
  components: {
    'number-box': NumberBox,
  },
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>

div.dark-layout .statistics-card {
  background-color: rgba(219, 223, 249, 0.8);
  color: rgb(35, 38, 41);
  border-color: rgb(180, 182, 225);
}

div.light-layout .statistics-card {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
  border-color: rgb(109, 128, 150);
}

div.bordered-layout .statistics-card {
  background-color: rgba(196, 208, 224, 0.4);
  color: rgb(47, 68, 90);
  border-color: rgb(109, 128, 150);
}

.statistics-card {
  padding: 12px;
  border-radius: 5px;
  width: auto;
  border: 0.1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

</style>
